import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-800 text-white p-3 md:p-4 text-xs md:text-sm relative z-50">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <p className="mb-2 md:mb-0">Made by Kai Detmers</p>
        <Link 
          to="/impressum" 
          className="text-white hover:text-gray-300 transition duration-150 ease-in-out"
        >
          {t('Impressum')}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
