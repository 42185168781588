import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import GameSession from './GameSession';
import Home from './Home';
import Login from './Login';
import Header from './Header';
import Footer from './Footer';
import Impressum from './Impressum';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import CreateSession from './CreateSession';

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSessionCreated = (sessionId) => {
    navigate(`/session/${sessionId}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color="#36D7B7" loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="flex flex-col min-h-screen">
        <Header user={user} onSignOut={handleSignOut} />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home user={user} />} />
            <Route path="/session/:id" element={<GameSession user={user} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/create-session" element={<CreateSession onSessionCreated={handleSessionCreated} />} />
          </Routes>
        </main>
        {location.pathname === '/' && <Footer />}
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t('Got it!')}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t('Cookie Consent')} <Link to="/impressum" className="text-white underline">{t('Learn More')}</Link>
      </CookieConsent>
    </div>
  );
}

export default AppContent;
