import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FaUser,FaUserPlus, FaSignOutAlt, FaCamera, FaSignInAlt, FaGlobe, FaEdit, FaSave, FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import ImageUpload from './ImageUpload';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, updateProfile } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import logo from './logo.png';
import imageCompression from 'browser-image-compression';
import { ClipLoader } from 'react-spinners';
import { db } from '../firebase';

function Header({ user, onSignOut, sessionId, onUserUpdate }) {
  const [showMenu, setShowMenu] = useState(false);
  const [allowMenuClose, setAllowMenuClose] = useState(true);
  const { t, i18n } = useTranslation();
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);

  const [editingUsername, setEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const usernameInputRef = useRef(null);

  const handleImageUpload = async (file) => {
    if (!file || !user) return;

    setIsUploading(true);
    const auth = getAuth();
    const storageRef = ref(storage, `profileImages/${user.uid}`);

    try {
      // Compression options
      const options = {
        maxSizeMB: 1, // Increased to 1MB
        maxWidthOrHeight: 800, // Increased for better quality
        useWebWorker: true
      };

      const compressedFile = await imageCompression(file, options);

      const snapshot = await uploadBytes(storageRef, compressedFile);

      const downloadURL = await getDownloadURL(snapshot.ref);

      // Update user profile in Firebase Authentication
      await updateProfile(auth.currentUser, { photoURL: downloadURL });

      // Update user data in Firestore
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { photoURL: downloadURL });

      // Update player data in the session
      if (sessionId) {
        const playerRef = doc(db, 'sessions', sessionId, 'players', user.uid);
        await updateDoc(playerRef, { photoURL: downloadURL });
      }

      // Force a re-render by updating the user state
      if (typeof onUserUpdate === 'function') {
        const updatedUser = { ...user, photoURL: downloadURL };
        onUserUpdate(updatedUser);
      } else {
        console.warn('onUserUpdate is not a function, user state not updated');
      }

      // Fetch the updated user data to confirm the change
      const updatedUserDoc = await getDoc(doc(db, 'users', user.uid));

    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        allowMenuClose &&
        showMenu &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, allowMenuClose]);

  const handleMenuItemClick = (action) => {
    setAllowMenuClose(false);
    action();
    setTimeout(() => {
      setAllowMenuClose(true);
      setShowMenu(false);
    }, 100);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const handleUsernameChange = useCallback(async () => {
    if (newUsername.trim()) {
      try {
        if (user) {
          // Update Firebase Auth profile
          const auth = getAuth();
          await updateProfile(auth.currentUser, {
            displayName: newUsername.trim()
          });

          // Update Firestore user document if exists
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            displayName: newUsername.trim()
          });

          // Update player in session if in a session
          if (sessionId) {
            const playerRef = doc(db, 'sessions', sessionId, 'players', user.uid);
            await updateDoc(playerRef, {
              name: newUsername.trim()
            });
          }
        } else {
          // Handle non-registered user name change
          localStorage.setItem('username', newUsername.trim());
        }
        
        setEditingUsername(false);
        setNewUsername('');
        // Force a re-render
        onUserUpdate && onUserUpdate({});
      } catch (error) {
        console.error('Error updating username:', error);
      }
    }
  }, [newUsername, user, sessionId, onUserUpdate]);

  useEffect(() => {
    if (editingUsername && usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, [editingUsername]);

  const renderMenu = () => (
    <div 
      ref={menuRef}
      className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl py-2 z-[999]" 
      onMouseEnter={() => setAllowMenuClose(false)}
      onMouseLeave={() => setAllowMenuClose(true)}
    >
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex justify-between items-center">
          {!editingUsername ? (
            <>
              <p className="text-sm font-medium text-gray-900 truncate">
                {user ? (user.displayName || user.email) : (localStorage.getItem('username') || t('Guest'))}
              </p>
              <button
                onClick={() => setEditingUsername(true)}
                className="text-blue-600 hover:text-blue-800 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200 flex items-center"
                aria-label={t('Edit')}
              >
                <FaEdit size={16} className="mr-1" />
                <span className="text-sm">{t('Edit Name')}</span>
              </button>
            </>
          ) : (
            <div className="flex items-center w-full">
              <input
                ref={usernameInputRef}
                type="text"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                className="flex-grow px-2 py-1 text-sm border rounded text-gray-900"
                placeholder={t('Enter your name')}
              />
              <button
                onClick={handleUsernameChange}
                className="text-green-600 hover:text-green-800 p-1 rounded-full hover:bg-green-100 transition-colors duration-200 ml-2"
                aria-label={t('Save')}
              >
                <FaCheck size={16} />
              </button>
            </div>
          )}
        </div>
      </div>
      {user && (
        <div className="px-4 py-2">
          <ImageUpload onImageUpload={handleImageUpload}>
            <button className="flex items-center w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition duration-150 ease-in-out">
              <FaCamera className="mr-3 text-gray-400" />
              {user.photoURL ? t('Change Image') : t('Add Profile Image')}
            </button>
          </ImageUpload>
        </div>
      )}
      <div className="px-4 py-2">
        <button
          onClick={() => changeLanguage(i18n.language === 'en' ? 'de' : 'en')}
          className="flex items-center w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition duration-150 ease-in-out"
        >
          <FaGlobe className="mr-3 text-gray-400" />
          {t('Language')}
        </button>
      </div>
      {user && (
        <div className="px-4 py-2">
          <button
            onClick={() => handleMenuItemClick(onSignOut)}
            className="flex items-center w-full text-left px-3 py-2 text-sm text-red-600 hover:bg-red-100 rounded-md transition duration-150 ease-in-out"
          >
            <FaSignOutAlt className="mr-3" /> {t('Sign Out')}
          </button>
        </div>
      )}
      {!user && (
        <div className="px-4 py-2">
          <Link
            to="/login"
            className="flex items-center w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md transition duration-150 ease-in-out"
          >
            <FaSignInAlt className="mr-3 text-gray-400" />
            {t('Login / Register')}
          </Link>
        </div>
      )}
    </div>
  );

  const renderUserAvatar = () => {
    if (isUploading) {
      return (
        <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
          <ClipLoader color="#4B5563" size={24} />
        </div>
      );
    }
    if (user && user.photoURL) {
      return <img src={user.photoURL} alt="Profile" className="w-10 h-10 rounded-full" />;
    }
    return (
      <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center">
        <span className="text-xl text-white">
          {user ? (user.displayName ? user.displayName[0].toUpperCase() : (user.email ? user.email[0].toUpperCase() : 'U'))
               : (localStorage.getItem('username') ? localStorage.getItem('username')[0].toUpperCase() : 'G')}
        </span>
      </div>
    );
  };

  return (
    <header className="py-4 relative z-[999]">
      <div className="max-w-[calc(100%-2rem)] mx-auto flex justify-between items-center px-4">
        <Link to="/" className="flex items-center">
          <img src={logo} alt={t('Agile Poker')} className="h-12 w-auto" />
        </Link>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <button
              ref={menuButtonRef}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              className="flex items-center focus:outline-none"
              aria-label={t('User menu')}
            >
              {renderUserAvatar()}
              <span className="ml-2 text-white">
                {user ? (user.displayName || user.email || t('User')) : (localStorage.getItem('username') || t('Guest'))}
              </span>
            </button>
            {showMenu && renderMenu()}
          </div>
          {!user && (
            <Link to="/login" className="text-white hover:text-gray-200 transition duration-150 ease-in-out">
              <FaUserPlus size={24} />
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
