import React from 'react';
import { useTranslation } from 'react-i18next';

function VotingResults({ results, isRangeEstimation }) {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg p-4 shadow-md">
      <h3 className="text-lg font-bold mb-3">{t('Voting Results')}</h3>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <p className="text-sm text-gray-600">{t('Low Average')}</p>
          <p className="text-xl font-bold">{results.lowAverage}</p>
        </div>
        {isRangeEstimation && (
          <div>
            <p className="text-sm text-gray-600">{t('High Average')}</p>
            <p className="text-xl font-bold">{results.highAverage}</p>
          </div>
        )}
        <div>
          <p className="text-sm text-gray-600">{t('Total Average')}</p>
          <p className="text-xl font-bold">{results.totalAverage}</p>
        </div>
      </div>
    </div>
  );
}

export default VotingResults;