import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore, doc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { nanoid } from 'nanoid';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyC1zuPQMcrvTKKuAdgJbsi8WHkqVhEpCE8",
  authDomain: "planning-poker-ff8cd.firebaseapp.com",
  projectId: "planning-poker-ff8cd",
  storageBucket: "planning-poker-ff8cd.appspot.com",
  messagingSenderId: "318579388387",
  appId: "1:318579388387:web:2162c45f37add91c5bfad6",
  databaseURL: "https://planning-poker-ff8cd-default-rtdb.europe-west1.firebasedatabase.app/"
};

const app = initializeApp(firebaseConfig);
export const realtimeDb = getDatabase(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// If you're running Firebase locally, uncomment the following line:
// connectFunctionsEmulator(functions, "localhost", 5001);

export const createSession = async (sessionName) => {
  const sessionId = nanoid(); // Generate a unique session ID
  const sessionRef = doc(db, 'sessions', sessionId);
  await setDoc(sessionRef, {
    name: sessionName,
    createdAt: serverTimestamp(),
    accessCode: sessionId // Use the session ID as the access code
  });
  return sessionId;
};

export const updateSessionName = async (sessionId, newName) => {
  const sessionRef = doc(db, 'sessions', sessionId);
  await updateDoc(sessionRef, {
    name: newName
  });
};