import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Session Name": "Session Name",
      "Enter session name": "Enter session name",
      "Copy Session ID": "Copy Session ID",
      "Copied!": "Copied!",
      "Participants": "Participants",
      "Low Estimate": "Low Estimate",
      "High Estimate": "High Estimate",
      "Estimate": "Estimate",
      "Submit Estimate": "Submit Estimate",
      "Reveal Estimates": "Reveal Estimates",
      "Reset": "Reset",
      "Show Drawing Area": "Show Drawing Area",
      "Hide Drawing Area": "Hide Drawing Area",
      "Leave Session?": "Leave Session?",
      "Do you really want to leave the session? Your data in this session will be lost.": "Do you really want to leave the session? Your data in this session will be lost.",
      "Cancel": "Cancel",
      "Leave": "Leave",
      "Join Session": "Join Session",
      "Enter your username": "Enter your username",
      "Select Estimation Method": "Select Estimation Method",
      "Range Estimation (Low/High)": "Range Estimation (Low/High)",
      "The high estimate must be higher than the low estimate.": "The high estimate must be higher than the low estimate.",
      "Agile Poker": "Agile Poker",
      "Create Session": "Create Session",
      "Enter Session ID": "Enter Session ID",
      "Loading...": "Loading...",
      "Create New Session": "Create New Session",
      "Creating...": "Creating...",
      "Please enter a session ID.": "Please enter a session ID.",
      "The specified session does not exist.": "The specified session does not exist.",
      "An error occurred. Please try again.": "An error occurred. Please try again.",
      "Please enter a session name.": "Please enter a session name.",
      "An error occurred while creating the session. Please try again.": "An error occurred while creating the session. Please try again.",
      "Enter Spectator Mode": "Enter Spectator Mode",
      "Exit Spectator Mode": "Exit Spectator Mode",
      "Coffee Symbol": "Coffee symbol (☕) means 'I'm not estimating this round'",
      "Voting Results": "Voting Results",
      "Total Votes": "Total Votes",
      "Coffee Breaks": "Coffee Breaks",
      "Low Average": "Low Average",
      "High Average": "High Average",
      "Low Mode": "Low Mode",
      "High Mode": "High Mode",
      "Agreement": "Agreement",
      "Exit Spectator": "Exit Spectator",
      "Spectator": "Spectator",
      "Join Spectator": "Join Spectator",
      "Leave Spectator": "Leave Spectator",
      "Settings": "Settings",
      "Sign Out": "Sign Out",
      "Login / Register": "Login / Register",
      "User Settings": "User Settings",
      "Language": "Language",
      "Profile Image": "Profile Image",
      "Upload Image": "Upload Image",
      "Jira API Key": "Jira API Key",
      "Save API Key": "Save API Key",
      "API Key saved successfully": "API Key saved successfully",
      "Error saving API Key": "Error saving API Key",
      "Back to Home": "Back to Home",
      "Unnamed Session": "Unnamed Session",
      "Session Not Found": "Session Not Found",
      "Error Loading Session": "Error Loading Session",
      "Join": "Join",
      "Enter your name": "Enter your name",
      "Cookie Consent": "This website uses cookies to ensure you get the best experience on our website.",
      "Learn More": "Learn more",
      "Got it!": "Got it!",
      "Impressum": "Impressum",
      "Collaborative estimation for agile teams": "Collaborative estimation for agile teams",
      "Streamline your planning, boost team engagement": "Enhance your planning process and elevate team collaboration",
      "Please enter a username:": "Please enter a username:", // New key for Google sign-in prompt
      "Username is required for registration": "Username is required for registration", // New key for registration error
      "Session": "Session",
      "Copy ID": "Copy ID",
      "Copy Link": "Copy Link",
      "Spectate": "Spectate",
      "Leave": "Leave",
      "Welcome to Planning Poker": "Welcome to Planning Poker",
      "Estimate your project tasks with your team": "Estimate your project tasks with your team",
      "Stories": "Stories",
      "Enter new story or paste Jira link": "Enter new story or paste Jira link",
      "Story Points": "Story Points",
      "Current Story": "Current Story",
      "Session ID copied!": "Session ID copied!",
      "Session link copied!": "Session link copied!",
      "Edit Name": "Edit Name"
    }
  },
  de: {
    translation: {
      "Session Name": "Sitzungsname",
      "Enter session name": "Sitzungsname eingeben",
      "Copy Session ID": "Session-ID kopieren",
      "Copied!": "Kopiert!",
      "Participants": "Teilnehmer",
      "Low Estimate": "Niedrige Schätzung",
      "High Estimate": "Hohe Schätzung",
      "Estimate": "Schätzung",
      "Submit Estimate": "Schätzung abgeben",
      "Reveal Estimates": "Schätzungen aufdecken",
      "Reset": "Zurücksetzen",
      "Show Drawing Area": "Zeichenbereich anzeigen",
      "Hide Drawing Area": "Zeichenbereich ausblenden",
      "Leave Session?": "Sitzung verlassen?",
      "Do you really want to leave the session? Your data in this session will be lost.": "Möchten Sie wirklich die Sitzung verlassen? Ihre Daten in dieser Sitzung gehen verloren.",
      "Cancel": "Abbrechen",
      "Leave": "Verlassen",
      "Join Session": "Sitzung beitreten",
      "Enter your username": "Benutzernamen eingeben",
      "Select Estimation Method": "Schätzmethode auswählen",
      "Range Estimation (Low/High)": "Bereichsschätzung (Niedrig/Hoch)",
      "The high estimate must be higher than the low estimate.": "Die hohe Schätzung muss höher als die niedrige Schätzung sein.",
      "Agile Poker": "Agile Poker",
      "Create Session": "Sitzung erstellen",
      "Enter Session ID": "Sitzungs-ID eingeben",
      "Loading...": "Lädt...",
      "Create New Session": "Neue Sitzung erstellen",
      "Creating...": "Wird erstellt...",
      "Please enter a session ID.": "Bitte geben Sie eine Sitzungs-ID ein.",
      "The specified session does not exist.": "Die angegebene Sitzung existiert nicht.",
      "An error occurred. Please try again.": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
      "Please enter a session name.": "Bitte geben Sie einen Sitzungsnamen ein.",
      "An error occurred while creating the session. Please try again.": "Bei der Erstellung der Sitzung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "Enter Spectator Mode": "Beobachtermodus aktivieren",
      "Exit Spectator Mode": "Beobachtermodus verlassen",
      "Coffee Symbol": "Kaffeesymbol (☕) bedeutet 'Ich schätze diese Runde nicht'",
      "Voting Results": "Abstimmungsergebnisse",
      "Total Votes": "Gesamtstimmen",
      "Coffee Breaks": "Kaffeepausen",
      "Low Average": "Niedriger Durchschnitt",
      "High Average": "Hoher Durchschnitt",
      "Low Mode": "Häufigster niedriger Wert",
      "High Mode": "Häufigster hoher Wert",
      "Agreement": "Übereinstimmung",
      "Exit Spectator": "Beobachtermodus verlassen",
      "Spectator": "Beobachter",
      "Join Spectator": "Als Beobachter beitreten",
      "Leave Spectator": "Beobachtermodus verlassen",
      "Settings": "Einstellungen",
      "Sign Out": "Abmelden",
      "Login / Register": "Anmelden / Registrieren",
      "User Settings": "Benutzereinstellungen",
      "Language": "Sprache",
      "Profile Image": "Profilbild",
      "Upload Image": "Bild hochladen",
      "Jira API Key": "Jira API-Schlüssel",
      "Save API Key": "API-Schlüssel speichern",
      "API Key saved successfully": "API-Schlüssel erfolgreich gespeichert",
      "Error saving API Key": "Fehler beim Speichern des API-Schlüssels",
      "Back to Home": "Zurück zur Startseite",
      "Unnamed Session": "Unbenannte Sitzung",
      "Session Not Found": "Sitzung nicht gefunden",
      "Error Loading Session": "Fehler beim Laden der Sitzung",
      "Join": "Beitreten",
      "Enter your name": "Geben Sie Ihren Namen ein",
      "Cookie Consent": "Diese Website verwendet Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu gewährleisten.",
      "Learn More": "Mehr erfahren",
      "Got it!": "Verstanden!",
      "Impressum": "Impressum",
      "Collaborative estimation for agile teams": "Kollaborative Schätzung für agile Teams",
      "Streamline your planning, boost team engagement": "Verbessern Sie Ihren Planungsprozess und fördern Sie die Teamzusammenarbeit",
      "Please enter a username:": "Bitte geben Sie einen Benutzernamen ein:", // New key for Google
      "Session": "Sitzung",
      "Copy ID": "ID kopieren",
      "Copy Link": "Link kopieren",
      "Spectate": "Beobachten",
      "Leave": "Verlassen",
      "Welcome to Planning Poker": "Willkommen beim Planning Poker",
      "Estimate your project tasks with your team": "Schätzen Sie Ihre Projektaufgaben mit Ihrem Team",
      "Stories": "Stories",
      "Enter new story or paste Jira link": "Neue Story eingeben oder Jira-Link einfügen",
      "Story Points": "Story-Punkte",
      "Current Story": "Aktuelle Story",
      "Session ID copied!": "Session-ID kopiert!",
      "Session link copied!": "Session-Link kopiert!",
      "Edit Name": "Namen ändern"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
