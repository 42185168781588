import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserFriends, FaCopy, FaEye, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

function SessionHeader({ sessionName, sessionId, players, isSpectator, onSessionNameChange, onToggleSpectatorMode, onCopy }) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempSessionName, setTempSessionName] = useState(sessionName);
  const [copiedItem, setCopiedItem] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setTempSessionName(sessionName);
  };

  const handleSaveClick = () => {
    onSessionNameChange({ target: { value: tempSessionName } });
    setIsEditing(false);
  };

  const copyToClipboard = async (text, type) => {
    try {
      const textToCopy = type === 'id' 
        ? sessionId 
        : `${window.location.origin}/session/${sessionId}`;
      
      await navigator.clipboard.writeText(textToCopy);
      setCopiedItem(type);
      setShowToast(true);
      onCopy(type);
      
      setTimeout(() => {
        setShowToast(false);
        setCopiedItem(null);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <>
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white shadow-lg rounded-lg p-4"
      >
        <div className="flex flex-wrap items-center justify-between space-y-2 sm:space-y-0">
          <div className="flex-grow flex items-center min-w-0">
            <span className="text-lg font-bold text-gray-800 whitespace-nowrap mr-2">{t('Session')}:</span>
            <div className="flex items-center flex-grow min-w-0 group">
              <div className="flex items-center flex-grow min-w-0 relative">
                <AnimatePresence mode="wait">
                  {isEditing ? (
                    <div className="flex items-center w-full">
                      <motion.input
                        key="input"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        type="text"
                        value={tempSessionName}
                        onChange={(e) => setTempSessionName(e.target.value)}
                        className="text-lg font-bold text-gray-800 bg-transparent border-b-2 border-blue-500 focus:outline-none w-full"
                        autoFocus
                      />
                    </div>
                  ) : (
                    <div className="flex items-center w-full">
                      <motion.h2
                        key="heading"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="text-lg font-bold text-gray-800 truncate"
                      >
                        {sessionName}
                      </motion.h2>
                      <button
                        onClick={handleEditClick}
                        className="text-blue-500 hover:text-blue-600 transition-colors duration-200 ml-1 p-1 rounded-full hover:bg-blue-50"
                      >
                        <FaPencilAlt size={12} />
                      </button>
                    </div>
                  )}
                </AnimatePresence>
                {isEditing && (
                  <button
                    onClick={handleSaveClick}
                    className="text-green-500 hover:text-green-600 transition-colors duration-200 ml-1 p-1 rounded-full hover:bg-green-50"
                  >
                    <FaCheck size={12} />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <motion.div 
              whileHover={{ scale: 1.05 }}
              className="flex items-center space-x-1 bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs"
            >
              <FaUserFriends size={12} />
              <span className="truncate">{players.length}</span>
            </motion.div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onToggleSpectatorMode}
              className={`px-2 py-1 rounded-full flex items-center space-x-1 transition-colors duration-200 text-xs ${
                isSpectator
                  ? 'bg-blue-500 text-white'
                  : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
              }`}
            >
              <FaEye size={12} />
              <span className="truncate">{isSpectator ? t('Leave') : t('Spectate')}</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => copyToClipboard(sessionId, 'id')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded-full flex items-center justify-center transition-colors duration-200 text-xs"
            >
              <FaCopy size={12} className="mr-1" />
              <span className="truncate">{t('Copy ID')}</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => copyToClipboard(`${window.location.origin}/session/${sessionId}`, 'link')}
              className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded-full flex items-center justify-center transition-colors duration-200 text-xs"
            >
              <FaCopy size={12} className="mr-1" />
              <span className="truncate">{t('Copy Link')}</span>
            </motion.button>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {copiedItem && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-2"
          >
            <div className="inline-flex items-center bg-green-100 text-green-700 px-4 py-2 rounded-full shadow-lg">
              <FaCheck className="mr-2" size={12} />
              <span className="text-sm font-medium">
                {copiedItem === 'id' 
                  ? t('Session ID copied!') 
                  : t('Session link copied!')}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default SessionHeader;