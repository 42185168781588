import React from 'react';
import { useTranslation } from 'react-i18next';

const Impressum = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="flex flex-col items-center justify-center min-h-screen py-16 relative z-10">
        <div className="bg-white bg-opacity-20 p-8 rounded-lg shadow-lg mb-8 transform transition-transform duration-300 backdrop-filter backdrop-blur-lg max-w-4xl w-full">
          <h1 className="text-4xl font-bold mb-6 text-white text-center">{t('Impressum')}</h1>
          
          <div className="text-white space-y-6">
            <Section title={t('Angaben gemäß § 5 TMG')}>
              <p>
                Kai Detmers<br />
                Kommodore-Ziegenbein-Allee 10<br />
                28217 Bremen
              </p>
            </Section>

            <Section title={t('Kontakt')}>
              <p>E-Mail: t3mr0i@gmail.com</p>
            </Section>

            <Section title={t('Haftungsausschluss (Disclaimer)')}>
              <SubSection title={t('Haftung für Inhalte')}>
                <p>{t('Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.')}</p>
              </SubSection>
              <SubSection title={t('Haftung für Links')}>
                <p>{t('Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.')}</p>
              </SubSection>
            </Section>

            <Section title={t('Datenschutzerklärung')}>
              <SubSection title={t('Datenschutz')}>
                <p>{t('Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.')}</p>
                <p>{t('Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.')}</p>
                <p>{t('Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.')}</p>
                <p>{t('Quelle: eRecht24')}</p>
              </SubSection>

              <SubSection title={t('Datenschutzerklärung für die Nutzung von Google Analytics')}>
                <p>{t('Durch Verwendung dieser Website wird Google Analytics eingesetzt, ein Webanalysedienst der Google LLC („Google“). Die Nutzung umfasst die Betriebsart „Universal Analytics“. Hierdurch ist es möglich, Daten, Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivitäten eines Nutzers geräteübergreifend zu analysieren. Dieser Datenschutzhinweis wird zur Verfügung gestellt von www.intersoft-consulting.de.')}</p>
                <p>{t('Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um eine IP-Anonymisierung erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter https://www.google.com/analytics/terms/de.html bzw. unter https://policies.google.com/?hl=de.')}</p>
                <p>{t('Zwecke der Verarbeitung: Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.')}</p>
                <p>{t('Rechtsgrundlage: Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S.1 lit. a DSGVO.')}</p>
                <p>{t('Empfänger / Kategorien von Empfängern: Der Empfänger der erhobenen Daten ist Google.')}</p>
                <p>{t('Übermittlung in Drittstaaten: Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf Grundlage des Angemessenheitsbeschlusses der Europäischen Kommission in die USA übermittelt. Das Zertifikat können Sie hier abrufen.')}</p>
                <p>{t('Dauer der Datenspeicherung: Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.')}</p>
                <p>{t('Betroffenenrechte: Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.')}</p>
                <p>{t('Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das Browser-Add-on herunterladen und installieren. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen durchführen. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren.')}</p>
              </SubSection>

              <SubSection title={t('Datenschutzerklärung für die Verwendung von Google AdSense')}>
                <p>{t('Diese Website verwendet Google AdSense. Es handelt sich hierbei um einen Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, zur Einbindung von Werbeanzeigen. Google AdSense verwendet Cookies. Dies sind Dateien, durch deren Speicherung auf Ihrem PC Google die Daten Ihrer Benutzung unserer Website analysieren kann. Zudem werden bei Google AdSense zusätzlich Web Beacons verwendet, nicht sichtbare Grafiken, die es Google ermöglichen, Klicks auf dieser Website, den Verkehr auf dieser und ähnliche Informationen zu analysieren.')}</p>
                <p>{t('Die über Cookies und Web Beacons erhaltenen Informationen, Ihre IP-Adresse sowie die Auslieferung von Werbeformaten werden an einen Server von Google mit Standort in den USA übermittelt und dort gespeichert. Google wird diese gesammelten Informationen möglicherweise an Dritte weitergeben, wenn dies gesetzlich erforderlich ist oder Google gegenüber Dritten die Datenverarbeitung in Auftrag gibt. Allerdings wird Google Ihre IP-Adresse zusammen mit den anderen gespeicherten Daten zusammenführen.')}</p>
              </SubSection>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-6">
    <h2 className="text-2xl font-semibold mb-3">{title}</h2>
    <div className="pl-4 border-l-2 border-white">{children}</div>
  </div>
);

const SubSection = ({ title, children }) => (
  <div className="mb-4">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

export default Impressum;
