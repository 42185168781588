import React from 'react';

const scaleOptions = [
  { value: 'fibonacci', label: 'Fibonacci', info: 'Folge: 0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89. Gut für relative Größenschätzungen.' },
  { value: 'modifiedFibonacci', label: 'Modifizierte Fibonacci', info: 'Folge: 0, 1, 2, 3, 5, 8, 13, 20, 40, 100. Ähnlich wie Fibonacci, aber mit angepassten höheren Werten.' },
  { value: 'tShirt', label: 'T-Shirt Größen', info: 'Größen: XXS, XS, S, M, L, XL, XXL. Gut für grobe Größenschätzungen.' },
  { value: 'progression', label: 'Progression', info: 'Folge: 0, 1, 2, 4, 6, 8, 16, 32, 64. Bietet eine Mischung aus linearem und exponentiellem Wachstum.' },
  { value: 'linear', label: 'Linear', info: 'Folge: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10. Gut für gleichmäßige, vorhersehbare Aufwandsschätzungen.' },
  { value: 'largeUncertainSmall', label: 'Groß/Unsicher/Klein', info: 'Einfache Kategorisierung in drei Größen. Gut für schnelle, grobe Einschätzungen.' },
];

function SessionSetup({ handleScaleSelect, isRangeEstimation, setIsRangeEstimation }) {
  return (
    <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-4xl mx-auto relative z-10">
      <h2 className="text-2xl font-bold mb-6 text-center">Schätzmethode auswählen</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {scaleOptions.map((scaleOption) => (
          <div
            key={scaleOption.value}
            className="bg-white hover:bg-gray-100 p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300"
            onClick={() => handleScaleSelect(scaleOption.value)}
          >
            <h3 className="text-lg font-bold mb-2">{scaleOption.label}</h3>
            <p className="text-sm text-gray-600">{scaleOption.info}</p>
          </div>
        ))}
      </div>
      <div className="mt-6 flex items-center justify-center">
        <input
          type="checkbox"
          id="isRangeEstimation"
          checked={isRangeEstimation}
          onChange={(e) => setIsRangeEstimation(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="isRangeEstimation">Bereichsschätzung (Niedrig/Hoch)</label>
      </div>
    </div>
  );
}

export default SessionSetup;