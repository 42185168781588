import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export const AnimatedTooltip = ({ children, content, isOpen, onOpenChange }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsHovered(isOpen);
    }
  }, [isOpen]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (onOpenChange) onOpenChange(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (onOpenChange) onOpenChange(false);
  };

  return (
    <div
      className="relative group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-50 left-1/2 transform -translate-x-1/2 -translate-y-full -top-4"
          >
            <div className="bg-white rounded-lg shadow-xl p-4 min-w-[200px]">
              {content}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
