import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function HomeDescription() {
  const { i18n } = useTranslation();

  const content = {
    en: {
      welcome: "Welcome to Planning Poker!",
      description: "Planning Poker is a collaborative estimation technique often used in agile software development teams. It helps teams reach a consensus on the effort or size of development tasks.",
      howItWorks: "How it works:",
      steps: [
        "Each participant selects an estimate from the available options.",
        "The estimates are revealed simultaneously to avoid bias.",
        "The team discusses the results and agrees on an estimate.",
      ],
      estimationMethods: "Estimation Methods",
      methodsDescription: "We offer various estimation methods to meet your team's requirements and preferences:",
      methods: [
        {
          name: "Fibonacci Sequence",
          description: "The Fibonacci sequence (0, 1, 2, 3, 5, 8, 13, 21, 34, 55) is commonly used to estimate relative sizes. Each number is the sum of the two preceding ones. This method is well-suited for tasks with varying levels of complexity and accounts for increasing uncertainty with larger estimates.",
        },
        {
          name: "Modified Fibonacci Sequence",
          description: "A variant of the Fibonacci sequence with adjusted values (0, 1, 2, 3, 5, 8, 13, 20, 40, 100). This method is similar to the standard Fibonacci sequence but uses rounder values for larger estimates to facilitate discussion.",
        },
        {
          name: "T-Shirt Sizes",
          description: "This method categorizes tasks into T-shirt sizes such as XS, S, M, L, and XL. It's a simple and intuitive way to broadly categorize tasks by size without committing to specific numbers.",
        },
        {
          name: "Custom Linear Scale",
          description: "With a custom linear scale (e.g., 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10), teams can set their own sequence of numbers or values. This provides flexibility and allows the estimation method to be tailored to the team's specific needs and preferences.",
        },
        {
          name: "Large/Uncertain/Small Estimation",
          description: "A simple method where tasks are categorized into three groups: 'Large', 'Uncertain', and 'Small'. This method is suitable for quick, rough estimates and can be helpful when the team has little experience with estimations or when a more detailed estimate is not necessary.",
        },
      ],
      instructions: "Instructions",
      instructionsDescription: "How to use the Planning Poker tool:",
      instructionSteps: [
        "Choose an estimation method and decide whether you want to perform a range estimation (Low/High).",
        "Enter your username and join the session.",
        "Select your estimate for each task.",
        "Once all participants have submitted their estimates, the results can be revealed.",
        "Discuss the results with the team and agree on an estimate for each task.",
        "Reset the estimates and start the next round.",
      ],
    },
    de: {
      welcome: "Willkommen bei Planning Poker!",
      description: "Planning Poker ist eine kollaborative Schätzmethode, die häufig in agilen Softwareentwicklungsteams verwendet wird. Es hilft Teams, Konsens über den Aufwand oder die Größe von Entwicklungsaufgaben zu erreichen.",
      howItWorks: "So funktioniert es:",
      steps: [
        "Jeder Teilnehmer wählt eine Schätzung aus den verfügbaren Optionen aus.",
        "Die Schätzungen werden gleichzeitig aufgedeckt, um Verzerrungen zu vermeiden.",
        "Das Team diskutiert die Ergebnisse und einigt sich auf eine Schätzung.",
      ],
      estimationMethods: "Schätzmethoden",
      methodsDescription: "Wir bieten verschiedene Schätzmethoden an, um den Anforderungen und Präferenzen deines Teams gerecht zu werden:",
      methods: [
        {
          name: "Fibonacci-Folge",
          description: "Die Fibonacci-Folge (0, 1, 2, 3, 5, 8, 13, 21, 34, 55) wird häufig verwendet, um relative Größen zu schätzen. Jede Zahl ist die Summe der beiden vorhergehenden Zahlen. Diese Methode eignet sich gut für Aufgaben mit unterschiedlichen Komplexitätsstufen und berücksichtigt die zunehmende Unsicherheit bei größeren Schätzungen.",
        },
        {
          name: "Modifizierte Fibonacci-Folge",
          description: "Eine Variante der Fibonacci-Folge mit angepassten Werten (0, 1, 2, 3, 5, 8, 13, 20, 40, 100). Diese Methode ähnelt der Standard-Fibonacci-Folge, verwendet jedoch für größere Schätzungen rundere Werte, um die Diskussion zu erleichtern.",
        },
        {
          name: "T-Shirt-Größen",
          description: "Bei dieser Methode werden Aufgaben in T-Shirt-Größen wie XS, S, M, L und XL eingeteilt. Dies ist eine einfache und intuitive Möglichkeit, Aufgaben grob nach ihrer Größe zu kategorisieren, ohne sich auf spezifische Zahlen festzulegen.",
        },
        {
          name: "Benutzerdefinierte lineare Skala",
          description: "Mit einer benutzerdefinierten linearen Skala (z.B. 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10) können Teams ihre eigene Sequenz von Zahlen oder Werten festlegen. Dies bietet Flexibilität und ermöglicht es, die Schätzmethode an die spezifischen Anforderungen und Präferenzen des Teams anzupassen.",
        },
        {
          name: "Große/Unsicher/Kleine Schätzung",
          description: "Eine einfache Methode, bei der Aufgaben in drei Kategorien eingeteilt werden: 'Groß', 'Unsicher' und 'Klein'. Diese Methode eignet sich für schnelle, grobe Schätzungen und kann hilfreich sein, wenn das Team noch wenig Erfahrung mit Schätzungen hat oder wenn eine detailliertere Schätzung nicht erforderlich ist.",
        },
      ],
      instructions: "Anleitung",
      instructionsDescription: "So verwendest du das Planning Poker Tool:",
      instructionSteps: [
        "Wähle eine Schätzmethode aus und lege fest, ob du eine Bereichsschätzung (Niedrig/Hoch) durchführen möchtest.",
        "Gib deinen Benutzernamen ein und tritt der Sitzung bei.",
        "Wähle deine Schätzung für jede Aufgabe aus.",
        "Sobald alle Teilnehmer ihre Schätzungen abgegeben haben, können die Ergebnisse aufgedeckt werden.",
        "Diskutiere die Ergebnisse mit dem Team und einigt euch auf eine Schätzung für jede Aufgabe.",
        "Setze die Schätzungen zurück und beginne mit der nächsten Runde.",
      ],
    },
  };

  const currentLanguage = i18n.language;
  const text = content[currentLanguage] || content.en;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto mt-16"> {/* Added mt-16 for more top margin */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="bg-white p-8 rounded-lg shadow-md"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-2xl font-bold text-gray-800 mb-4"
        >
          {text.welcome}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-gray-600 mb-6"
        >
          {text.description}
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="text-gray-600 mb-6"
        >
          {text.howItWorks}
        </motion.p>
        <motion.ul
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="list-disc pl-6 mb-6 text-gray-600"
        >
          {text.steps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </motion.ul>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="bg-white p-8 rounded-lg shadow-md"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-2xl font-bold text-gray-800 mb-4"
        >
          {text.estimationMethods}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="text-gray-600 mb-6"
        >
          {text.methodsDescription}
        </motion.p>
        <motion.ul
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="list-disc pl-6 mb-6 text-gray-600 space-y-4"
        >
          {text.methods.map((method, index) => (
            <li key={index}>
              <strong>{method.name}:</strong> {method.description}
            </li>
          ))}
        </motion.ul>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="bg-white p-8 rounded-lg shadow-md md:col-span-2"
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="text-2xl font-bold text-gray-800 mb-4"
        >
          {text.instructions}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="text-gray-600 mb-6"
        >
          {text.instructionsDescription}
        </motion.p>
        <motion.ol
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1 }}
          className="list-decimal pl-6 text-gray-600"
        >
          {text.instructionSteps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </motion.ol>
      </motion.div>
    </div>
  );
}

export default HomeDescription;