import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, onSnapshot, updateDoc, setDoc, writeBatch, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Table from './Table';
import SessionHeader from './SessionHeader';
import JoinSessionForm from './JoinSessionForm';
import FloatingEstimationArea from './FloatingEstimationArea';
import EnhancedStoryList from './EnhancedStoryList';
import VotingResults from './VotingResults';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

function GameSession({ user }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();
  const { t } = useTranslation();

  const [session, setSession] = useState(null);
  const [sessionName, setSessionName] = useState('');
  const [players, setPlayers] = useState([]);
  const [selectedLowBet, setSelectedLowBet] = useState(null);
  const [selectedHighBet, setSelectedHighBet] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);
  const [isDrawingExpanded, setIsDrawingExpanded] = useState(false);
  const [scale, setScale] = useState('fibonacci');
  const [isScaleSet, setIsScaleSet] = useState(false);
  const [isRangeEstimation, setIsRangeEstimation] = useState(true);
  const [showLeaveWarning, setShowLeaveWarning] = useState(false);
  const [estimatesLocked, setEstimatesLocked] = useState(false);
  const [localUsername, setLocalUsername] = useState(localStorage.getItem('username') || '');
  const [isJoined, setIsJoined] = useState(false);
  const [error, setError] = useState(null);
  const [isSpectator, setIsSpectator] = useState(false);
  const [hasJiraCredentials, setHasJiraCredentials] = useState(false);
  const [stories, setStories] = useState([]);
  const [currentStory, setCurrentStory] = useState(null);
  const [isStoryListExpanded, setIsStoryListExpanded] = useState(true);
  const [resetTrigger, setResetTrigger] = useState(0);

  const scales = {
    fibonacci: [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '☕'],
    modifiedFibonacci: [0,1,2,3,5,8,13,21,40,80,100, '☕'],
    tShirt: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '☕'],
    progression: [0, 1, 2, 4, 6, 8, 16, 32, 64, '☕'],
    linear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '☕'],
    largeUncertainSmall: ['Groß', 'Unsicher', 'Klein', '☕'],
  };

  const joinProcessedRef = useRef(false);
  const [isJoiningSession, setIsJoiningSession] = useState(false);

  useEffect(() => {
    // Check if user is already joined
    const storedSessionId = localStorage.getItem('sessionId');
    const storedGuestId = localStorage.getItem('guestId');
    const storedUsername = localStorage.getItem('username');
    
    if (!joinProcessedRef.current && storedSessionId === id && (user || (storedGuestId && storedUsername))) {
      joinProcessedRef.current = true;
      setIsJoined(true);
    }
  }, [id, user]);

  const fetchSession = useCallback(async () => {
    if (!id) return;

    try {
      const sessionDoc = await getDoc(doc(db, 'sessions', id));
      if (sessionDoc.exists()) {
        const sessionData = sessionDoc.data();
        setSession(sessionData);
        setSessionName(sessionData.name || '');
        setScale(sessionData.scale || 'fibonacci');
        setIsRangeEstimation(sessionData.isRangeEstimation !== undefined ? sessionData.isRangeEstimation : true);
        setIsScaleSet(true);
      } else {
        setError('Session not found');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching session:', error);
      setError('Failed to fetch session');
    }
  }, [id, navigate]);

  const handleJoinSession = async (guestId, username) => {
    if (isJoiningSession || joinProcessedRef.current) return;
    setIsJoiningSession(true);
    
    try {
      // Clean up any existing entries for this user
      if (user) {
        const guestId = localStorage.getItem('guestId');
        if (guestId) {
          const guestRef = doc(db, 'sessions', id, 'players', guestId);
          await deleteDoc(guestRef);
          localStorage.removeItem('guestId');
        }
      }

      // Check if player already exists
      const playerId = user ? user.uid : guestId;
      const playerRef = doc(db, 'sessions', id, 'players', playerId);
      const playerDoc = await getDoc(playerRef);

      if (!playerDoc.exists()) {
        await setDoc(playerRef, {
          name: username,
          photoURL: user?.photoURL || null,
          isSpectator: false,
          hasPlacedBet: false,
          lowBet: null,
          highBet: null,
        });
      }

      localStorage.setItem('sessionId', id);
      if (!user) {
        localStorage.setItem('guestId', guestId);
        localStorage.setItem('username', username);
      }
      
      setIsJoined(true);
      setLocalUsername(username);
      joinProcessedRef.current = true;

    } catch (error) {
      console.error('Join session error:', error);
      setError('Failed to join session. Please try again.');
    } finally {
      setIsJoiningSession(false);
    }
  };

  const addPlayerToSession = async (playerId, playerName, photoURL = null) => {
    if (!id) {
      console.error('Session ID is missing');
      setError('Session ID is missing. Please try again.');
      return;
    }

    try {
      // Check if player already exists
      const playerRef = doc(db, 'sessions', id, 'players', playerId);
      const playerDoc = await getDoc(playerRef);
      
      if (!playerDoc.exists()) {
        await setDoc(playerRef, {
          name: playerName || 'Guest',
          photoURL: photoURL,
          isSpectator: false,
          hasPlacedBet: false,
          lowBet: null,
          highBet: null,
        });
      }
      setIsJoined(true);
    } catch (error) {
      console.error('Error adding player to session:', error);
      setError('Failed to join session. Please try again. Error: ' + error.message);
    }
  };

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  useEffect(() => {
    if (!session || !id) return;

    const checkAndAddPlayer = async () => {
      try {
        // If user is logged in
        if (user) {
          // Remove any existing guest entries for this user
          const guestId = localStorage.getItem('guestId');
          if (guestId) {
            const guestRef = doc(db, 'sessions', id, 'players', guestId);
            await deleteDoc(guestRef);
            localStorage.removeItem('guestId');
          }

          // Add or update logged-in user
          const playerRef = doc(db, 'sessions', id, 'players', user.uid);
          await setDoc(playerRef, {
            name: user.displayName || user.email,
            photoURL: user.photoURL,
            isSpectator: false,
            hasPlacedBet: false,
            lowBet: null,
            highBet: null,
          }, { merge: true });
          setIsJoined(true);
        } else {
          // Handle guest user - only if we have both guestId and username
          const storedGuestId = localStorage.getItem('guestId');
          const storedUsername = localStorage.getItem('username');
          
          if (storedGuestId && storedUsername) {
            // Check if this guest already exists in the session
            const playerRef = doc(db, 'sessions', id, 'players', storedGuestId);
            const playerDoc = await getDoc(playerRef);
            
            if (!playerDoc.exists()) {
              await setDoc(playerRef, {
                name: storedUsername,
                isSpectator: false,
                hasPlacedBet: false,
                lowBet: null,
                highBet: null,
              });
            }
            setIsJoined(true);
            setLocalUsername(storedUsername);
          }
        }
      } catch (error) {
        console.error('Error in checkAndAddPlayer:', error);
      }
    };

    // Only run once when component mounts
    const hasRun = localStorage.getItem('checkAndAddPlayerRun');
    if (!hasRun) {
      checkAndAddPlayer();
      localStorage.setItem('checkAndAddPlayerRun', 'true');
    }

    // Cleanup function
    return () => {
      localStorage.removeItem('checkAndAddPlayerRun');
    };
  }, [session, user, id]);

  useEffect(() => {
    if (!id) return;

    const subscribeToPlayers = () => {
      const playersCollection = collection(db, 'sessions', id, 'players');
      return onSnapshot(playersCollection, (snapshot) => {
        const updatedPlayers = snapshot.docs.map((doc) => {
          const playerData = doc.data();
          return {
            id: doc.id,
            name: playerData.name || '',
            lowBet: playerData.lowBet,
            highBet: playerData.highBet,
            photoURL: playerData.photoURL,
            isSpectator: playerData.isSpectator || false,
            hasPlacedBet: playerData.hasPlacedBet || false,
          };
        });
        setPlayers(updatedPlayers);
      }, (error) => {
        console.error('Error subscribing to players:', error);
        setError('Failed to subscribe to player updates');
      });
    };

    const unsubscribe = subscribeToPlayers();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [id]);

  useEffect(() => {
    if (!id) return;

    const sessionRef = doc(db, 'sessions', id);
    const unsubscribe = onSnapshot(sessionRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setIsRevealed(data.isRevealed || false);
        setEstimatesLocked(data.isRevealed || false);
        setStories(data.stories || []);
      }
    }, (error) => {
      console.error("Error listening to session changes:", error);
      setError('Failed to listen to session updates');
    });

    return () => unsubscribe();
  }, [id]);

  const handleLowBetSelect = (bet) => {
    setSelectedLowBet(bet);
    handleBetSubmit(bet, selectedHighBet);
  };

  const handleHighBetSelect = (bet) => {
    setSelectedHighBet(bet);
    handleBetSubmit(selectedLowBet, bet);
  };

  const handleBetSubmit = async (lowBet, highBet) => {
    const storedUsername = localStorage.getItem('username');
    const storedGuestId = localStorage.getItem('guestId');

    const playerId = user ? user.uid : storedGuestId;
    const username = user ? (user.displayName || user.email) : storedUsername;

    if (!playerId || !username) {
      console.error('Player information not found');
      setError('Player information not found. Please refresh the page and try again.');
      return;
    }

    if (isSpectator) return;

    try {
      const playerRef = doc(db, 'sessions', id, 'players', playerId);
      const betData = {
        name: username,
        lowBet: lowBet,
        highBet: isRangeEstimation ? highBet : lowBet,
        hasPlacedBet: true,
      };
      await setDoc(playerRef, betData, { merge: true });
    } catch (error) {
      console.error('Error submitting bet:', error);
      setError('Failed to submit bet. Please try again.');
    }
  };

  const handleReveal = async () => {
    try {
      const sessionRef = doc(db, 'sessions', id);
      await updateDoc(sessionRef, {
        isRevealed: true,
      });
      setIsRevealed(true);
      setEstimatesLocked(true);
    } catch (error) {
      console.error('Error revealing bets:', error);
      setError('Failed to reveal estimates');
    }
  };

  const handleReset = async () => {
    try {
      setSelectedLowBet(null);
      setSelectedHighBet(null);
      setIsRevealed(false);
      setEstimatesLocked(false);
      setResetTrigger(prev => prev + 1); // Increment resetTrigger

      const sessionRef = doc(db, 'sessions', id);
      await updateDoc(sessionRef, {
        isRevealed: false,
      });

      const batch = writeBatch(db);
      players.forEach((player) => {
        const playerRef = doc(db, 'sessions', id, 'players', player.id);
        batch.update(playerRef, {
          lowBet: null,
          highBet: null,
          hasPlacedBet: false,
        });
      });
      await batch.commit();
    } catch (error) {
      console.error('Error resetting bets:', error);
    }
  };

  const handleKickPlayer = async (playerId) => {
    try {
      const playerRef = doc(db, 'sessions', id, 'players', playerId);
      await deleteDoc(playerRef);
    } catch (error) {
      console.error('Error kicking player:', error);
    }
  };

  const handleSessionNameChange = (e) => {
    const newName = e.target.value;
    setSessionName(newName);
    debouncedHandleSessionNameChange(newName.trim());
  };

  const debouncedHandleSessionNameChange = useCallback(
    debounce(async (newName) => {
      try {
        const sessionRef = doc(db, 'sessions', id);
        await updateDoc(sessionRef, { name: newName });
      } catch (error) {
        console.error('Error updating session name:', error);
      }
    }, 500),
    [id]
  );

  const handleScaleSelect = async (selectedScale) => {
    if (!id) return;
    setScale(selectedScale);
    setIsScaleSet(true);

    const sessionRef = doc(db, 'sessions', id);
    await updateDoc(sessionRef, {
      scale: selectedScale,
      isRangeEstimation: isRangeEstimation
    });
  };

  const toggleSpectatorMode = async () => {
    if (!user && !localStorage.getItem('guestId')) {
      return;
    }

    const currentUserId = user ? user.uid : localStorage.getItem('guestId');


    try {
      const playerRef = doc(db, 'sessions', id, 'players', currentUserId);
      await updateDoc(playerRef, {
        isSpectator: !isSpectator
      });
      setIsSpectator(!isSpectator);
    } catch (error) {
      console.error('Error toggling spectator mode:', error);
    }
  };

  useEffect(() => {
    const checkJiraCredentials = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setHasJiraCredentials(
            !!userData.jiraApiKey &&
            !!userData.jiraDomain &&
            !!userData.jiraEmail &&
            !!userData.jiraProjectKey
          );
        }
      }
    };
    checkJiraCredentials();
  }, [user]);

  const handleNameChange = (newName) => {
    setLocalUsername(newName);
    localStorage.setItem('username', newName);
    const playerId = user ? user.uid : localStorage.getItem('guestId');
    if (playerId) {
      updateDoc(doc(db, 'sessions', id, 'players', playerId), { name: newName });
    }
  };

  const handleCopy = () => {
    const url = `${window.location.origin}/session/${id}`;
    navigator.clipboard.writeText(url)
      .then(() => {
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  };

  const handleStoryListToggle = (expanded) => {
    setIsStoryListExpanded(expanded);
  };

  const renderVotingResults = () => {
    if (!isRevealed || players.length === 0) return null;

    const validPlayers = players.filter(player => !player.isSpectator && player.lowBet !== null);
    if (validPlayers.length === 0) return null;

    const getNumericValue = (value) => {
      if (value === '☕') return Infinity;
      return typeof value === 'number' ? value : scales[scale].indexOf(value);
    };

    const lowEstimates = validPlayers.map(player => getNumericValue(player.lowBet));
    const highEstimates = isRangeEstimation 
      ? validPlayers.map(player => getNumericValue(player.highBet))
      : lowEstimates;

    const calculateAverage = (estimates) => {
      const finiteEstimates = estimates.filter(v => v !== Infinity && v !== null);
      return finiteEstimates.length > 0 
        ? finiteEstimates.reduce((a, b) => a + b, 0) / finiteEstimates.length 
        : null;
    };

    const lowAverage = calculateAverage(lowEstimates);
    const highAverage = calculateAverage(highEstimates);
    
    const totalAverage = isRangeEstimation && lowAverage !== null && highAverage !== null
      ? (lowAverage + highAverage) / 2
      : lowAverage;


    const getScaleValue = (value) => {
      if (value === null) return '-';
      if (value === Infinity) return '☕';
      
      const scaleValues = scales[scale].filter(v => v !== '☕').map(Number);
      const closestValue = scaleValues.reduce((prev, curr) => 
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
      
      return closestValue.toString();
    };

    const results = {
      lowAverage: getScaleValue(lowAverage),
      highAverage: isRangeEstimation ? getScaleValue(highAverage) : '-',
      totalAverage: getScaleValue(totalAverage)
    };


    return (
      <VotingResults
        results={results}
        isRangeEstimation={isRangeEstimation}
      />
    );
  };

  // Clean up function
  useEffect(() => {
    return () => {
      joinProcessedRef.current = false;
    };
  }, []);

  if (!isJoined) {
    // Only show join form if we don't have a username
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      // If we have a username, auto-join
      handleJoinSession(localStorage.getItem('guestId') || `guest_${Date.now()}`, storedUsername);
      return <div>Joining session...</div>;
    }
    
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
        <JoinSessionForm onJoin={handleJoinSession} />
        {error && <div className="mt-4 text-red-500 text-center">{error}</div>}
      </div>
    );
  }

  if (!session) {
    return <div>Loading...</div>;
  }

  if (!isScaleSet) {
    return (
      <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
        <div className="min-h-screen flex flex-col">
          <div className="p-4 min-h-screen flex items-center justify-center mb-28">
            <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-4xl mx-auto relative z-10">
              <h2 className="text-2xl font-bold mb-6 text-center">{t('Select Estimation Method')}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.entries(scales).map(([scaleKey, scaleValue]) => (
                  <div
                    key={scaleKey}
                    className="bg-white hover:bg-gray-100 p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300"
                    onClick={() => handleScaleSelect(scaleKey)}
                  >
                    <h3 className="text-lg font-bold mb-2">{t(scaleKey)}</h3>
                    <p className="text-sm text-gray-600">{scaleValue.join(', ')}</p>
                  </div>
                ))}
              </div>
              <div className="mt-6 flex items-center justify-center">
                <input
                  type="checkbox"
                  id="isRangeEstimation"
                  checked={isRangeEstimation}
                  onChange={(e) => setIsRangeEstimation(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="isRangeEstimation">{t('Range Estimation (Low/High)')}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="flex-grow flex overflow-hidden">
        <div className={`flex-grow overflow-y-auto p-4 transition-all duration-300 ${isStoryListExpanded ? 'mr-96' : 'mr-24'}`}>
          <div className="w-full mx-auto bg-white bg-opacity-90 rounded-xl shadow-2xl overflow-hidden relative z-10">
            <SessionHeader
              sessionName={sessionName}
              sessionId={id}
              players={players}
              isSpectator={isSpectator}
              onSessionNameChange={handleSessionNameChange}
              onToggleSpectatorMode={toggleSpectatorMode}
              onCopy={handleCopy}
              username={localUsername}
              onNameChange={handleNameChange}
            />
            <div className="p-4 bg-opacity-90 overflow-y-auto">
              {currentStory && (
                <div className="mb-4 p-2 bg-blue-100 rounded-lg">
                  <h3 className="text-lg font-bold mb-1">{t('Current Story')}</h3>
                  <p className="text-gray-800">{currentStory.title}</p>
                </div>
              )}
              
              <div className="mb-8 w-full">
                <Table
                  players={players}
                  isRevealed={isRevealed}
                  onKickPlayer={handleKickPlayer}
                  currentPlayerId={user ? user.uid : localStorage.getItem('guestId')}
                  isRangeEstimation={isRangeEstimation}
                  isSpectator={isSpectator}
                  sessionId={id}
                  resetTrigger={resetTrigger}
                />
              </div>
              
              {renderVotingResults()}
            </div>
          </div>
        </div>
        
        <EnhancedStoryList
          sessionId={id}
          stories={stories}
          scale={scales[scale] || []}
          onStorySelect={setCurrentStory}
          currentStory={currentStory}
          onExpand={handleStoryListToggle}
        />
      </div>
      
      {!isSpectator && scale && (
        <FloatingEstimationArea
          scale={scale}
          onLowBetSelect={handleLowBetSelect}
          onHighBetSelect={handleHighBetSelect}
          selectedLowBet={selectedLowBet}
          selectedHighBet={selectedHighBet}
          isRangeEstimation={isRangeEstimation}
          isRevealed={isRevealed}
          onReveal={handleReveal}
          onReset={handleReset}
        />
      )}
    </div>
  );
}

export default GameSession;
