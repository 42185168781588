import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { BackgroundGradientAnimation } from './BackgroundGradientAnimation';
import JoinWithSessionId from './JoinWithSessionId';
import HomeDescription from './HomeDescription';
import Footer from './Footer';

function Home() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleJoin = (sessionId) => {
    navigate(`/join/${sessionId}`);
  };

  const handleCreateSession = () => {
    navigate('/create-session');
  };

  return (
    <BackgroundGradientAnimation>
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow flex flex-col items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-8"
          >
            <h1 className="text-4xl font-bold text-white mb-2">{t('Welcome to Planning Poker')}</h1>
            <p className="text-xl text-white">{t('Estimate your project tasks with your team')}</p>
          </motion.div>

          <div className="w-full max-w-md">
            <JoinWithSessionId onJoin={handleJoin} error={error} />
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="mt-4"
            >
              <button
                onClick={handleCreateSession}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-300"
              >
                {t('Create New Session')}
              </button>
            </motion.div>
          </div>

          <HomeDescription />
        </div>
        <Footer />
      </div>
    </BackgroundGradientAnimation>
  );
}

export default Home;
