import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

function JoinWithSessionId({ onJoin, error: propError }) {
  const [sessionId, setSessionId] = useState('');
  const [error, setError] = useState(propError);
  const { t } = useTranslation();
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (sessionId.trim()) {
      try {
        const sessionRef = doc(db, 'sessions', sessionId.trim());
        const sessionSnap = await getDoc(sessionRef);
        
        if (sessionSnap.exists()) {
          onJoin(`join/${sessionId.trim()}`);
        } else {
          setError(t('Session not found. Please check the Session ID and try again.'));
        }
      } catch (error) {
        console.error('Error checking session:', error);
        setError(t('An error occurred. Please try again.'));
      }
    }
  };

  return (
    <motion.div 
      className="w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <form onSubmit={handleSubmit} className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg">
        <h2 className="text-2xl font-bold text-white mb-4 text-center">{t('Join Session')}</h2>
        <div className="relative mb-4">
          <input
            type="text"
            value={sessionId}
            onChange={(e) => {
              setSessionId(e.target.value);
              setError(null); // Clear error when input changes
            }}
            required
            className="w-full px-4 py-2 border-2 border-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 bg-white bg-opacity-20 text-white placeholder-gray-300"
            placeholder={t('Enter Session ID')}
            aria-label={t('Session ID')}
          />
        </div>
        {error && <p className="text-red-500 text-sm mt-1 mb-4">{error}</p>}
        <button
          type="submit"
          className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-colors duration-300"
          aria-label={t('Join Session')}
        >
          {t('Join Session')}
          <FaArrowRight className="ml-2" />
        </button>
      </form>
    </motion.div>
  );
}

export default JoinWithSessionId;